<template>
    <!-- <ts-loading-banner :loading="loading"> -->
        <table class="table  ui-table tw-table" border="1">
            <slot name="thead">
                <thead 
                 class="tw-bg-blue-500 tw-text-white"
                >
                    <tr>
                        <CheckableHead v-if="showCheckbox" />
                        <ExpandableHead v-if="expandable" />
                        <th
                            v-if="!showCheckbox"
                            class="tw-px-6 tw-py-3  tw-uppercase tw-text-center tw-w-3 page-header tw-border"
                        >
                            {{ $t("numeric") }}
                        </th>
                        <slot name="columns">
                            <template v-for="(column, key) in columns">
                                <th
																		v-if="showColumn(column)"
                                    :key="key"
                                    scope="col"
                                    class="tw-px-6 tw-py-3 tw-text-left  tw-uppercase tw-whitespace-nowrap page-header tw-border tw-border-gray-400"
                                    :style="showColumnStyle(column)"
                                    :class="showColumnClass(column)"
                                >
                                   <div class="tw-flex tw-justify-between">
                                    <p class="tw-mb-0"> {{ showColumnName(column) }}</p>
                                    <ColumnFilter
                                        :column="column"
                                        :sortedColumn="sortedColumn"
                                        @sort="setSortedColumn"
                                    />
                                   </div>
                                    
                                </th>
                            </template>
                        </slot>
                    </tr>
                    <TableProgressBar v-show="loading" />
                </thead>
            </slot>
            <slot name="tbody">
                <tbody class="tw-bg-white tw-divide-y tw-divide-gray-200">
                    <template v-for="(items, itemKey) in dataTable">
                        <ShouldGroup
                            :items="items"
                            :itemKey="itemKey"
                            v-slot:group="{ record, index, numeric }"
                            :key="itemKey"
                        >
                            <tr
                                :key="keyName !== null ? record[keyName] : index"
                                @click="raiseRowClickEvent({ index, record })"
                                @dblclick="
                                    raiseRowDoubleClickEvent({ index, record })
                                "
                                :style="{ cursor: mouseTypeOnRow }"
                                class="hover:tw-bg-blue-100 tw-transform tw-duration-200"
                                :class="{ 
                                    'tw-line-through tw-text-red-600': record.is_deleted ? record.is_deleted : false,
                                    'tw-bg-gray-100': numeric % 2 != 0,
                                    'tw-bg-blue-200': selectedIndex == numeric 
                                }"
                            >
                                <CheckableColumn
                                    :record="record"
                                    :checkBoxKeyValue="checkBoxKeyValue"
                                    v-if="showCheckbox"
                                />
                                <ExpandableColumn
                                    :index="index"
                                    v-if="expandable"
                                />
                                <td class="tw-text-center" v-if="!showCheckbox">{{ numeric  + 1 }}</td>
                                <slot :record="record" :index="index"></slot>
                            </tr>
                            <ExpandableRow v-if="expandableItems[index]">
                                <slot
                                    name="expand"
                                    :record="record"
                                    :index="index"
                                ></slot>
                            </ExpandableRow>
                        </ShouldGroup>
                    </template>
                </tbody>
            </slot>
            <slot name="tfooter"></slot>
        </table>
    <!-- </ts-loading-banner> -->
</template>

<script>
import { sortBy, debounce, transform, groupBy, reduce } from "lodash";
import CheckableHead from "./CheckableHead.vue";
import ExpandableHead from "./ExpandableHead.vue";
import CheckableColumn from "./CheckableColumn.vue";
import ExpandableColumn from "./ExpandableColumn.vue";
import ExpandableRow from "./ExpandableRow.vue";
import ShouldGroup from "./ShouldGroup.vue";
import ColumnFilter from "./ColumnFilter";
import TableProgressBar from "./TableProgressBar";

export default {
    name: "TsTable",
    components: {
        CheckableHead,
        ExpandableHead,
        CheckableColumn,
        ExpandableColumn,
        ExpandableRow,
        ShouldGroup,
        ColumnFilter,
        TableProgressBar
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        records: {
            type: Array,
            default: () => []
        },
        showCheckbox: {
            type: Boolean,
            default: false
        },
        checkableCondition: {
            default: null
        },
        keyName: {
            type: String,
            default: null
        },
        expandable: {
            type: Boolean,
            default: false
        },
        rowGroup: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        },
        mouseTypeOnRow: {
            type: String,
            default: "default"
        },
        checkBoxKeyValue: {
            type: String
        }
    },
    data() {
        return {
            selectedIndex: undefined,
            dataTable: [],
            checkedItems: [],
            allItemsChecked: false,
            expandableItems: [],
            sortedColumn: null
        };
    },
    computed: {
        checkableItems() {
            return this.records.filter(item => this.shouldShowBox(item));
        },
        columnLength() {
            let length = this.columns.length;

            if (this.showCheckbox) {
                ++length;
            }

            if (this.expandable) {
                ++length;
            }

            return length;
        }
    },
    created() {
        this.records.forEach((item, index) => {
            this.expandableItems[index] = false;
        });
    },

    methods: {
        getDataTable() {
            let dataTable = this.getSortDataTable();

            if (this.rowGroup !== undefined) {
                dataTable = transform(
                    groupBy(dataTable, this.iterateeGroupBy),
                    (result, value, key) => {
                        result[key] = {
                            data: value,
                            expand: true
                        };
                    },
                    {}
                );
            }

            return dataTable;
        },
        getSortDataTable() {
            const nestedColumnValueReducer = (carrier, accumulator) => {
                return reduce(
                    carrier,
                    (carry, key) => {
                        if (carry === null || carry === undefined) return;

                        return carry[key];
                    },
                    accumulator
                );
            };

            if (this.sortedColumn !== null) {
                try {
                    if (this.sortedColumn.order === 1) {
                        return sortBy(this.records, o => {
                            let sortVal = nestedColumnValueReducer(
                                this.sortedColumn.sortKey.split("."),
                                o
                            );

                            if (sortVal === undefined) throw "No sort data";

                            return sortVal;
                        });
                    }

                    if (this.sortedColumn.order === -1) {
                        return sortBy(this.records, o => {
                            let sortVal = nestedColumnValueReducer(
                                this.sortedColumn.sortKey.split("."),
                                o
                            );

                            if (sortVal === undefined) throw "No sort data";

                            return sortVal;
                        }).reverse();
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            return this.records;
        },
        iterateeGroupBy(record) {
            const itemKeys = this.rowGroup.field.split(".");

            return reduce(itemKeys, (item, key) => item[key], record);
        },
        toggleCheckingAllItems() {
            if (this.allItemsChecked === true) {
                if(this.checkBoxKeyValue) {
                    this.checkedItems = this.checkableItems.map(el => el[this.checkBoxKeyValue])
                    return;
                }
                this.checkedItems = this.checkableItems;
            } else {
                this.checkedItems = [];
            }
        },
        checkItem() {
            this.allItemsChecked =
                this.checkedItems.length === this.records.length;
        },
        raisedCheckItemsEvent() {
            this.$emit("check-items", this.checkedItems);
        },
        shouldShowBox(item) {
            if (this.checkableCondition === null) {
                return true;
            }

            return (
                item[this.checkableCondition[0]] == this.checkableCondition[1]
            );
        },
        toggleExpandedRow(index) {
            this.$set(
                this.expandableItems,
                index,
                !this.expandableItems[index]
            );
        },
        showColumnName(column) {
            if (column instanceof Object) {
                return column.name;
            }

            return column;
        },
        showColumnStyle(column) {
            if (column instanceof Object) {
                return column.style;
            }

            return {};
        },
        showColumnClass(column) {
            if (column instanceof Object) {
                return column.class;
            }
        },
				showColumn(col){
					if(col.hidden == undefined){
						return true
					}else {
						return col.hidden
					}
				},
        filterable(column) {
            return column instanceof Object && column.filter !== undefined;
        },
        getNestedPropertyValue(item, propsString) {
            let props = propsString.split("."),
                temp = item[props[0]];

            for (let i = 1; i < props.length; i++) {
                temp = temp[props[i]];
            }

            return temp;
        },
        setSortedColumn(sortedColumn) {
            this.sortedColumn = sortedColumn;
        },
        fireFilterEvent: debounce(function($event, $filterName) {
            this.$emit($filterName, $event.target.value);
        }, 1000),
        raiseRowClickEvent({ index, record }) {
            this.selectedIndex = index
            this.$emit("row-click", { index, record });
        },
        raiseRowDoubleClickEvent({ index, record }) {
            this.$emit("row-dblclick", { index, record });
        }
    },
    watch: {
        checkedItems: function() {
            this.raisedCheckItemsEvent();
        },
        records: {
            handler() {
                this.dataTable = this.getDataTable();
            },
            deep: true,
            immediate: true
        },
        sortedColumn: {
            handler() {
                this.dataTable = this.getDataTable();
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style scoped>
table thead tr th {
    font-size: 14px;
    border: 1px solid rgb(228, 228, 228);
    padding: 2px 7px !important;
}
.table > :not(caption) > * > * {
    border: 1px solid #c7c7c7;
}

</style>


